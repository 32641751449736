export default {
  appEnv: 'stage',
  apiURL: 'https://stage.getfloorplan.com/api/',
  apiBackofficeURL: 'https://backend-stage.estate.hart-digital.com',
  sentry: {
    environment: 'stage',
    dsn: 'https://b325a122c5fee4e981d33cf1a9715c70@sentry.hart-digital.com/8',
  },
  domains: {
    'getfloorplan.com': 'en',
    'hart-estate.ru': 'ru',
  },
};
